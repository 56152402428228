import Image from "next/image";
import Link from "next/link";
import DarkMode from "../mode/DarkMode";
import Logo from "./../../public/images/logo.png";
import WhiteLogo from "./../../public/images/logo_white.png";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";
import {
  isChildrenPageActive,
  isParentPageActive,
} from "../../utils/daynamicNavigation";
import { useEffect, useState } from "react";

import SmoothScrollLink from "../scroll-link/SmoothScrollLink";

export default function Header02() {
  const [toggle, setToggle] = useState(false);
  const [isScroll, setScroll] = useState(false);
  const [isCollapse, setCollapse] = useState(null);

  const route = useRouter();

  // sticky menu
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 4) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
  }, []);

  // window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });


  // mobile page collapse
  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };

  return (
    <>
      {/* main desktop menu sart*/}
      <header
        className={`js-page-header page-header--transparent fixed top-0 z-20 w-full bg-black/[.15] backdrop-blur transition-colors ${
          isScroll ? "js-page-header--is-sticky" : ""
        }`}
      >
        <div className="flex items-center px-6 py-6 xl:px-24 ">
          <Link className="shrink-0 font-medium" href="/">
            <a className="font-medium flex items-center justify-center">
              <Image
                src="/images/logo.png"
                height={30}
                width={30}
                layout="fixed"
                alt=""
              />
              <span className="ml-2 font-pixel text-base font-medium">CoinHubExpo</span>
            </a>
          </Link>
          {/* End  logo */}

          <div className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
            <nav className="navbar w-full">
              <ul className="flex flex-col lg:flex-row">
                {/* home */}
                <li className="js-nav-dropdown group relative">
                  <SmoothScrollLink href="#position-hero">
                      Home
                  </SmoothScrollLink> 
                </li>
                <li className="js-nav-dropdown group relative">
                  <SmoothScrollLink href="#position-about">
                      About
                  </SmoothScrollLink> 
                </li>
                <li className="js-nav-dropdown group relative">
                  <SmoothScrollLink href="#position-topic">
                      Topics
                  </SmoothScrollLink> 
                </li>
                <li className="js-nav-dropdown group relative">
                  <SmoothScrollLink href="#position-speaker">
                      Speakers
                  </SmoothScrollLink> 
                </li>
                <li className="js-nav-dropdown group relative">
                  <SmoothScrollLink href="#position-sponsor">
                      Sponsors
                  </SmoothScrollLink> 
                </li>
                <li>
                  
                    <button className="bg-main hover:bg-main-hover text-white font-medium py-2 px-8 rounded-full transition duration-300 transform hover:rotate-15">
                        Get Ticket
                    </button>
                  
                </li>
                
              </ul>
            </nav>
            {/* End menu for desktop */}

            {/* End header right content (metamask and other) for desktop */}
          </div>
          {/* header menu conent end for desktop */}

          <div className="ml-auto flex lg:hidden">
            <button
              className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
              aria-label="open mobile menu"
              onClick={() => setToggle(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
              </svg>
            </button>
          </div>
          {/* End header right content user  for mobile */}
        </div>
        {/* End flex item */}
      </header>
      {/* main desktop menu end */}

      {/* Start mobile menu and it's other materials */}
      <div
        className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
          toggle ? "nav-menu--is-open" : "hidden"
        }`}
      >
        <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
          <div className="dark:hidden">
            <Image
              src={Logo}
              height={28}
              width={28}
              alt="CoinHubExpo"
              className="max-h-7 h-auto "
            />
          </div>
          <div className="hidden dark:block">
            <div className="flex items-center">
              <Image
                src={Logo}
                height={28}
                width={28}
                alt="CoinHubExpo"
              />
              <span className="ml-2 font-pixel">CoinHubExpo</span>
            </div>
          </div>
          <button
            className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            onClick={() => setToggle(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
            </svg>
          </button>
        </div>
        {/* mobile menu top header content */}

        <div className="relative w-full"></div>
        <nav className="navbar w-full" style={{marginTop: '60px'}}>
          <ul className="flex flex-col lg:flex-row m-4">
            {/* home */}
            <li className="js-nav-dropdown group relative">
              <SmoothScrollLink href="#position-hero" togglerFn={setToggle}>
                  Home
              </SmoothScrollLink> 
            </li>
            <li className="js-nav-dropdown group relative">
              <SmoothScrollLink href="#position-about" togglerFn={setToggle}>
                  About
              </SmoothScrollLink> 
            </li>
            <li className="js-nav-dropdown group relative">
              <SmoothScrollLink href="#position-topic" togglerFn={setToggle}>
                  Topics
              </SmoothScrollLink> 
            </li>
            <li className="js-nav-dropdown group relative">
              <SmoothScrollLink href="#position-speaker" togglerFn={setToggle}>
                  Speakers
              </SmoothScrollLink> 
            </li>
            <li className="js-nav-dropdown group relative">
              <SmoothScrollLink href="#position-sponsor" togglerFn={setToggle}>
                  Sponsors
              </SmoothScrollLink> 
            </li>
            <li>
                <button className="bg-main hover:bg-main-hover text-white font-medium mt-4 py-2 px-8 rounded-full transition duration-300 transform hover:rotate-15">
                    Get Ticket
                </button>
            </li>
            
          </ul>
        </nav>
        {/* End navbar mobile menu  */}

      </div>
      {/* End mobile menu and it's other materials */}
    </>
  );
}
