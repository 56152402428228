import { useEffect } from 'react';
import Link from 'next/link';

const SmoothScrollLink = ({ href, children, togglerFn }) => {
  const smoothScroll = (event) => {
    event.preventDefault();
    const targetId = href.substring(1);
    const targetElement = document.getElementById(targetId);
    if (togglerFn) {
        console.log("togglerFn", "here");
        togglerFn(false);
    }
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const id = hash.substring(1);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <Link href={href}>
      <a className="font-pixel text-2xs hover:text-pink focus:text-pink font-medium flex py-3.5 lg:px-5" onClick={smoothScroll}>{children}</a>
    </Link>
  );
};

export default SmoothScrollLink;